.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid gray;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.App {
  background-color: #322834;
  text-align: center;
  background-size: cover;
  height: 10000;
  width:fit-content;
}

.chooseFile{
  width: '20vw';
  padding: 5;
  color: 'green';
  flex: 10;

}

.rt-td {
  white-space: normal !important;
}

.btn-group.special {
  display: flex;
}

.special .btn {
  flex: 10;
  background-color: rgb(234, 141, 65);
}
.special .Uploadbtn {
  flex: 10;
  background-color: hsla(36, 98%, 57%, 1);
}

.footerbtn{
  flex: 10;

  background-color: rgb(8, 68, 231);
}




.backdrop{
  background-color: #322834;
  background-size: cover;
  height: 10000;
  width: 10000;
}

.box {
  display: flex;
  flex-direction: row;
  height: 15vmin;
  font-size: xx-large;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: left;
}


.App-logo {
  height: 15vmin;
  pointer-events: none;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #1251d0;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

#inputID::placeholder {
  color: white;
  opacity: 1;
}

.tabby{
  float: left;
  white-space: normal !important;
  overflow: visible;
  background-color:coral;


}

